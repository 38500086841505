// @flow strict
import React from 'react';
import Helmet from 'react-helmet';
import type { Node as ReactNode } from 'react';
import { useSiteMetadata } from '../../hooks';
import styles from './Layout.module.scss';

type Props = {
  children: ReactNode,
  title: string,
  pageUrl?: string,
  description?: string,
  socialImage?: string,
};

const Layout = ({
  children, title, description, socialImage, pageUrl, pageType
}: Props) => {
  const { author, url, googleVerification, title: siteTitle} = useSiteMetadata();
  const metaImage = typeof socialImage !== 'undefined' ? socialImage : author.photo;
  const metaImageUrl = url + metaImage;
  const metaPageUrl = typeof pageUrl !== 'undefined' ? url + pageUrl : undefined;

  return (
    <div className={styles.layout}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content={siteTitle} />
        <meta property="og:image" content={metaImageUrl} />
        <meta property="og:type" content={pageType} />
        <meta property="og:url" content={metaPageUrl} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={metaImageUrl} />
        <meta name="google-site-verification" content={googleVerification} />
      </Helmet>
      {children}
    </div>
  );
};

export default Layout;
