// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import styles from './Copyright.module.scss';

type Props = {
  copyright: string,
};

const Copyright = ({ copyright }: Props) => (
  <div className={styles['copyright']}>
    {copyright}
    <Link to="/pages/terms"> Terms of Use & Privacy Policy.</Link>
  </div>
);

export default Copyright;
